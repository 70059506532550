import React from "react"
import Header from "./header"
import HeaderIndex from "./headerIndex"

// Este componente devuelve el Header correcto (index o no index) incluyendo la
// foto de portada correcta, dependiendo del ancho del window, usando lifecycle methods

class MainHeader extends React.Component {
  // basado en https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs

  constructor(props) {
    super(props)
    this.state = { width: 0, height: 0 }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  correctImgReturner() {
    // este metodo devuelve la imagen correcta para la portada.
    // si es que el window tiene mas de 750px de ancho,
    // devuelve la img guardada en el prop portadaDesk
    let imgVar
    let iWidth = this.state.width

    if (iWidth > 750) {
      imgVar = this.props.portadaDesk
    } else {
      imgVar = this.props.portadaMob
    }

    return imgVar
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  render() {
    const correctImg = this.correctImgReturner()
    // hubo un problema durante el build, a causa del uso del window property (?)
    // ya que durante el build no existe un window, se usa el siguiente IF para
    // poder completarlo
    if (typeof window !== `undefined`) {
      return this.props.isIndex === true ? (
        <HeaderIndex bgImg={correctImg} />
      ) : (
        <Header bgImg={correctImg} title={this.props.title} />
      )
    } else {
      return <p> </p>
    }
  }
}

export default MainHeader
