import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Menu from "./menu.js"
import styles from "./headerIndex.module.css"

const HeaderIndex = ({ bgImg }) => {
  const data = useStaticQuery(graphql`
    query HeaderIndexQuery {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

return (
  <header className={styles.header}>
    <div className={styles.mainContainer}>
      <div className={styles.filter}>
      </div>
      <div className={styles.logoContainer}>
          <Img fluid={data.logo.childImageSharp.fluid} />
      </div>
      <div className={styles.imgContainer}>
          <Img fluid={bgImg.childImageSharp.fluid} />
      </div>
    </div>
      <div className={styles.menuContainer}>
        <Menu/>
      </div>
  </header>
  
)
}
export default HeaderIndex
