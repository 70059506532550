import React from "react"
import Img from "gatsby-image"
import Menu from "./menu.js"
import styles from "./header.module.css"

const Header = ({ bgImg, title }) => {
  return (
    <header className={styles.header}>
      <div className={styles.mainContainer}>
        <div className={styles.filter}></div>
        <div className={styles.titleContainer}>
          <h1>{title}</h1>
        </div>
        <div className={styles.imgContainer}>
          <Img fluid={bgImg.childImageSharp.fluid} />
        </div>
      </div>
      <div className={styles.menuContainer}>
        <Menu />
      </div>
    </header>
  )
}

export default Header
