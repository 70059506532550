import React from "react"
import { Link } from "gatsby"
import styles from "./menu.module.css"

const Dropdown = ({ classesContainer }) => {
  return (
    <div className={classesContainer}>
      <Link to="/">INICIO</Link>
      <Link to="/clases">CLASES</Link>
      <Link to="/instructores">INSTRUCTORES</Link>
      <a href="https://eventos.allineapilates.com">EVENTOS</a>
      <Link to="/contacto">CONTACTO</Link>
    </div>
  )
}

class Menu extends React.Component {
  state = {
    displayDropdown: false,
  }

  handleClick() {
    this.setState(prevState => ({
      // toggles state value
      displayDropdown: !prevState.displayDropdown,
    }))
    return
  }

  render() {
    return (
      <div className={styles.mainContainer}>
        <div className={styles.buttonContainer}>
          <button onClick={() => this.handleClick()}>MENU</button>
        </div>
        <Dropdown
          classesContainer={
            this.state.displayDropdown === true
              ? styles.dropdown
              : [styles.dropdown, styles.hideDropdown].join(" ")
          }
        />
        <div className={styles.desktopLinkContainer}>
          <Link to="/">INICIO</Link>
          <Link to="/clases">CLASES</Link>
          <Link to="/instructores">INSTRUCTORES</Link>
          <a href="https://eventos.allineapilates.com">EVENTOS</a>
          <Link to="/contacto">CONTACTO</Link>
        </div>
      </div>
    )
  }
}

export default Menu
